@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: radial-gradient(ellipse at 12% 50%, rgba(244, 206, 20, 1) 0%, rgba(73, 94, 87, 1) 100%);
  --gradient-texts: linear-gradient(358deg, rgba(244, 206, 20, 1) 0%, rgba(73, 94, 87, 1) 100%);;
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
