.menu-container {
    padding: 2rem;
    border-radius: 5px;
    background: #EDEFEE;
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.menu-header h2 {
    font-family: var(--font-family);
    font-size: 40px;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: -0.05rem;
}

.menu-button {
    display: flex;
}

.menu-button button {
    background-color: #F4CE14;
    color: black;
    font-family: var(--font-family);
    font-weight: 500;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
    box-shadow: 0 2px 3px 2px rgb(23, 21, 11, 15%);
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    justify-content: center;
}

@media screen and (max-width: 1300px) {
    .cards-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        transition: all 0.3s ease-in;
    }
}

@media screen and (max-width: 1000px) {
    .menu-header h2 {
        font-size: 35px;
        font-weight: 700;
    }
}

@media screen and (max-width: 600px) {
    .menu-header h2 {
        font-size: 23px;
        font-weight: 700;
    }

    .menu-button button {
        font-size: 12px;
    }


    .cards-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
        transition: all 0.3s ease-in;
    }
}