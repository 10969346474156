.header {
    background-color: #495e57;
    padding: 20px 0;
    border-radius: 5px;

}

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4rem;
    gap: 3rem;
}


.header-banner {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.header-banner h2 {
    font-size: 60px;
    font-family: var(--font-family);
    font-weight: 800;
    line-height: 75px;
    letter-spacing: -0.01em;
}

.header-banner h3 {
    font-family: var(--font-family);
    font-size: 30px;
    color: white;
    margin-bottom: 2rem;
}

.header-banner p {
    font-size: 20px;
    line-height: 2;
    margin-bottom: 3rem;
    font-family: var(--font-family);
    color: white;
    text-align: left;

}

.header-banner button {
    background-color: #F4CE14;
    color: black;
    padding: 10px 15px 11px;
    border: none;
    border-radius: 5px;
    font-family: var(--font-family);
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 4px 6px 2px rgb(23, 21, 11, 20%);
}

.header-banner button:hover {
    box-shadow: 0 4px 6px 2px rgb(23, 21, 11, 40%);
}

.header-banner-image {
    flex: 0.5;
    display: flex;
    width: 100%;
    justify-content: center;
}

.header-banner-image img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

@media (max-width: 1000px) {

    .header-container {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .header-banner {
        flex: 1;
        gap: 1rem;
        margin-bottom: 2rem;
    }

    .header-banner h2 {
        font-size: 48px;
    }

    .header-banner h3 {
        font-size: 25px;
    }

    .header-banner p {
        font-size: 18px;
    }

    .header-banner button {
        font-size: 17px;
    }

    .header-banner-image img {
        object-fit: cover;
        height: 30rem;
    }

}

@media (max-width: 500px) {

    .header-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 1rem;
    }

    .header-banner {
        flex: 1;
        gap: 0;
        margin-bottom: 2rem;
    }

    .header-banner h2 {
        font-size: 38px;
        letter-spacing: 0.0001rem;
    }

    .header-banner h3 {
        font-size: 22px;
    }

    .header-banner p {
        margin-bottom: 2rem;
        font-size: 15px;
    }

    .header-banner button {
        font-size: 14px;
    }

    .header-banner-image img {
        height: 20rem;
    }

}

