.booking-form {
    background: #D9D9D9;
    border-radius: 5px;
    padding: 2rem;

}

.booking-form-container {
    background: #EDEFEE;
    padding: 40px 20px;
    border: 2px double black;
    border-radius: 10px;
}

.formField {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.formField-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-around;
    gap: 2rem;
}

.booking-form-firstName,
.booking-form-lastName,
.booking-form-email,
.booking-form-phoneNumber,
.booking-form-password,
.booking-form-confirmPassword,
.booking-form-age,
.booking-form-gender,
.booking-form-interests,
.booking-form-dob,
.booking-form-date,
.booking-form-time,
.booking-form-guests,
.booking-form-occasion {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 0.5rem;
}

.booking-form-firstName label,
.booking-form-lastName label,
.booking-form-email label,
.booking-form-phoneNumber label,
.booking-form-password label,
.booking-form-confirmPassword label,
.booking-form-age label,
.booking-form-gender label,
.booking-form-interests label:nth-child(1),
.booking-form-dob label,
.booking-form-date label,
.booking-form-time label,
.booking-form-guests label,
.booking-form-occasion label {
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 600;
    word-spacing: 0.2rem;
}

.booking-form-firstName input,
.booking-form-lastName input,
.booking-form-email input,
.booking-form-phoneNumber input,
.booking-form-password input,
.booking-form-confirmPassword input,
.booking-form-age input,
.booking-form-gender select,
.booking-form-dob input,
.booking-form-date input,
.booking-form-time select,
.booking-form-guests input,
.booking-form-occasion select {
    border: none;
    border-radius: 5px;
    background: #D9D9D9;
    padding: 0.5rem;
    width: 100%;
    height: 2.5rem;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 14px;
}

.booking-form-interests label:not(:nth-child(1)) {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
}

.formField-button {
    display: flex;
    justify-content: center;
}

.formField-button input {
    background-color: rgb(244, 206, 20);
    color: black;
    font-family: var(--font-family);
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 6px 2px rgb(23, 21, 11, 10%);
}

input.input-error,
select.input-error {
    border: solid;
    border-color: maroon;
}

p.error {
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 600;
    color: maroon;
}

.formField-button input:disabled {
    opacity: 0.35;
}

@media screen and (max-width: 900px) {
    .formField-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-around;
        gap: 2rem;
    }

}

@media screen and (max-width: 650px) {
    .formField-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-around;
        gap: 2rem;
    }

    .booking-form-firstName label,
    .booking-form-lastName label,
    .booking-form-email label,
    .booking-form-phoneNumber label,
    .booking-form-password label,
    .booking-form-confirmPassword label,
    .booking-form-age label,
    .booking-form-gender label,
    .booking-form-interests label:nth-child(1),
    .booking-form-dob label,
    .booking-form-date label,
    .booking-form-time label,
    .booking-form-guests label,
    .booking-form-occasion label {
        font-family: var(--font-family);
        font-size: 13px;
        font-weight: 600;
    }


    .booking-form-firstName input,
    .booking-form-lastName input,
    .booking-form-email input,
    .booking-form-phoneNumber input,
    .booking-form-password input,
    .booking-form-confirmPassword input,
    .booking-form-age input,
    .booking-form-gender select,
    .booking-form-dob input,
    .booking-form-date input,
    .booking-form-time select,
    .booking-form-guests input,
    .booking-form-occasion select {
        height: 2.5rem;
        font-family: var(--font-family);
        font-weight: 300;
        font-size: 13px;
    }

    .booking-form-interests label:not(:nth-child(1)) {
        font-family: var(--font-family);
        font-size: 13px;
    }

    .formField-button input {
        font-size: 13px;
        font-weight: 500;
    }

}

