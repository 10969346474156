* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

.App {
    background: linear-gradient(180deg, rgba(244, 206, 20, 1) 0%, rgba(73, 94, 87, 1) 100%);
}

a {
    color: unset;
    text-decoration: none;
}

.section__padding {
    padding: 1rem 1.5rem;
}

.section__margin {
    margin: 1rem 1.5rem;
}

.gradient-text {
    background: var(--gradient-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.gradient-texts {
    background: var(--gradient-texts);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

/*Tablets*/
@media screen and (max-width: 900px) {
    .section__padding {
        padding: 0.5rem 0.5rem;
    }

    .section__margin {
        margin: 0.5rem 0.5rem;
    }

}

/*Mobile Devices*/
@media screen and (max-width: 550px) {
    .section__padding {
        padding: 0.5rem 0.5rem;
    }

    .section__margin {
        margin: 0.5rem 0.5rem;
    }

}